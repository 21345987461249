<template>
    <properties-block v-bind="$props" v-on="$listeners">

    </properties-block>
</template>

<script>
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";

// properties-block-column #hot-reload-debug
export default {
    name: `properties-block-column`,
    components: {PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
